import { Keyboard, Platform, Pressable, StyleSheet } from "react-native";
import { MeritsHeader } from "./MeritsHeader.mobile";
import { SearchableMeritsList } from "./SearchableMeritsList";
import { useEffect, useState } from "react";
import { useNavigation } from "@src/hooks";
import { useShareMeritsStore } from "@src/stores/shareMerits";
import type { Merit } from "@src/api/issuance";

const MeritsScreen = () => {
  const styles = StyleSheet.create({ fullFlex: { flex: 1 } });

  const navigation = useNavigation();

  const setStoredSelectedMeritIds = useShareMeritsStore(state => state.setSelectedMeritIds);

  const [isShareInProgress, setIsShareInProgress] = useState(false);
  const [selectedMeritIds, setSelectedMeritIds] = useState<ReadonlySet<Merit["id"]> | undefined>();

  // reset state for sharing if done/canceled
  useEffect(() => {
    if (!isShareInProgress) {
      setSelectedMeritIds(undefined);
    }
  }, [isShareInProgress]);

  const onPressShare = () => {
    setIsShareInProgress(true);
  };

  const onPressCancel = () => {
    setIsShareInProgress(false);
    setStoredSelectedMeritIds([]);
  };

  const onPressPreview = () => {
    setIsShareInProgress(false);
    if (selectedMeritIds !== undefined) {
      setStoredSelectedMeritIds([...selectedMeritIds]);
      navigation.navigate("CreateShareMeritsConfirmation");
    }
  };

  const content = (
    <>
      <MeritsHeader
        isPreviewButtonEnabled={(selectedMeritIds?.size ?? 0) > 0}
        isShareInProgress={isShareInProgress}
        onPressCancel={onPressCancel}
        onPressPreview={onPressPreview}
        onPressShare={onPressShare}
      />
      <SearchableMeritsList
        isShareInProgress={isShareInProgress}
        onPressItem={meritId => {
          if (!isShareInProgress) {
            navigation.navigate("MeritDetails", { meritId });

            return;
          }

          const newSet = new Set(selectedMeritIds);
          if (selectedMeritIds?.has(meritId) === true) {
            newSet.delete(meritId);
          } else {
            newSet.add(meritId);
          }
          setSelectedMeritIds(newSet);
        }}
        selectedMeritIds={selectedMeritIds}
      />
    </>
  );

  return Platform.OS === "web" ? (
    content
  ) : (
    <Pressable
      onPress={() => {
        Keyboard.dismiss();
      }}
      style={styles.fullFlex}
    >
      {content}
    </Pressable>
  );
};

export { MeritsScreen };
