import * as Linking from "expo-linking";
import { useLayoutType } from "./useLayoutType";
import type { LinkingOptions, PathConfigMap } from "@react-navigation/native";
import type { RootStackParamList, ScreenParamList } from "@src/navigation";

const pathConfigMap: PathConfigMap<ScreenParamList> = {
  CreatePersonaAddMerits: "personas/create/add-merits",
  CreatePersonaEnterName: "personas/create/enter-name",
  CreatePersonaMeritDetails: "personas/create/add-merits/merit-details/:meritId",
  CreateShareMeritsConfirmation: "share-merits/create-confirmation",
  Home: "home",
  LoggingIn: "login-with-popup",
  LoggingOut: "logout",
  Login: "login",
  MeritDetails: "merits/:meritId",
  Merits: "merits",
  OnboardingMerits: "onboarding/merits",
  OnboardingRegistration: "onboarding/registration",
  PersonaDetails: "personas/:personaId",
  Personas: "personas",
  PolicyRequestDetails: "verification",
  SomethingWentWrong: "something-went-wrong",
  TermsOfServiceAcceptance: "terms-of-service-acceptance",
};

export type UseLinking = () => LinkingOptions<RootStackParamList>;

export const useLinking = () => {
  const layoutType = useLayoutType();

  const prefix = Linking.createURL("/");
  const {
    CreateShareMeritsConfirmation,
    Home,
    LoggingIn,
    LoggingOut,
    Login,
    MeritDetails,
    Merits,
    OnboardingMerits,
    OnboardingRegistration,
    PersonaDetails,
    PolicyRequestDetails,
    SomethingWentWrong,
    TermsOfServiceAcceptance,
  } = pathConfigMap;

  if (layoutType === "desktop") {
    return {
      config: {
        screens: {
          CreateShareMeritsConfirmation,
          LoggingIn,
          LoggingOut,
          Login,
          MeritDetails,
          OnboardingMerits,
          OnboardingRegistration,
          PersonaDetails,
          PolicyRequestDetails,
          SideBarNavigator: {
            screens: {
              Home,
              Merits,
            },
          },
          SomethingWentWrong,
          TermsOfServiceAcceptance,
        },
      },
      prefixes: [prefix],
    };
  }

  return {
    config: {
      screens: {
        CreateShareMeritsConfirmation,
        DrawerNavigator: {
          screens: {
            BottomTabsNavigator: {
              screens: {
                Home,
                Merits,
              },
            },
          },
        },
        LoggingIn,
        LoggingOut,
        Login,
        MeritDetails,
        OnboardingMerits,
        OnboardingRegistration,
        PersonaDetails,
        PolicyRequestDetails,
        SomethingWentWrong,
        TermsOfServiceAcceptance,
      },
    },
    prefixes: [prefix],
  };
};
