import { Checkbox, useTheme } from "@merit/frontend-components";
import { ORG_LOGO_SIZE } from "@src/utils/constants/sizes";
import { OrgLogo } from "../OrgLogo";
import { PendingStateIndicator } from "../PendingStateIndicator";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { TruncatedBody } from "../TruncatedBody";
import { useGetTestProps } from "@src/hooks/useGetTestProps";
import { useRoute } from "@react-navigation/native";
import type { Props } from "./types";
import type { TextStyle, ViewStyle } from "react-native";

const MeritListItemMobile = ({
  isSelected = false,
  isShareInProgress = false,
  merit,
  onPress,
}: Props) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly flex: ViewStyle;
    readonly issuerImageContainer: ViewStyle;
    readonly item: ViewStyle;
    readonly nameContainer: ViewStyle;
    readonly meritName: TextStyle;
  }>({
    flex: {
      flex: 1,
    },
    issuerImageContainer: {
      borderRadius: theme.borderRadii.m,
      height: ORG_LOGO_SIZE,
      marginRight: theme.spacing.l,
      overflow: "hidden",
      width: ORG_LOGO_SIZE,
    },
    item: {
      alignItems: "center",
      flexDirection: "row",
      paddingVertical: theme.spacing.m,
    },
    meritName: {
      flex: 1,
      fontWeight: theme.fontWeights.semiBold,
      ...theme.fontSizes.m,
    },
    nameContainer: {
      alignItems: "center",
      flexDirection: "row",
    },
  });

  const getTestProps = useGetTestProps();
  const route = useRoute();

  return (
    <TouchableOpacity
      disabled={onPress === undefined}
      onPress={onPress}
      {...getTestProps({
        elementId: merit.id,
        elementName: "MeritListItem",
      })}
      style={styles.item}
    >
      <View style={styles.issuerImageContainer}>
        <OrgLogo merit={merit} />
      </View>
      <View style={styles.flex}>
        <View style={styles.nameContainer}>
          {merit.state?.name === "pending" ? <PendingStateIndicator /> : null}
          <TruncatedBody
            size="l"
            style={styles.meritName}
            testProps={{
              elementId: "meritName",
              elementName: "MeritListItem",
              screenName: route.name,
            }}
          >
            {merit.name}
          </TruncatedBody>
        </View>
        <TruncatedBody
          style={theme.fontSizes.s}
          testProps={{
            elementId: "issuingOrgName",
            elementName: "MeritListItem",
            screenName: route.name,
          }}
        >
          {merit.transformedFields.orgName}
        </TruncatedBody>
      </View>
      {isShareInProgress && (
        // TODO: add Accept button to Pending merits in list
        <Checkbox
          defaultChecked={isSelected}
          disabled={merit.state?.name !== "accepted"}
          onChange={() => {
            /* noop, parent handles checked state */
          }}
        />
      )}
    </TouchableOpacity>
  );
};

export { MeritListItemMobile };
