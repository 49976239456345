import { Body, LineSeparator, useTheme } from "@merit/frontend-components";
import { FlatList, StyleSheet, View } from "react-native";
import { MeritListItem, MeritListItemSkeleton } from "@src/components";
import { Trans } from "@lingui/macro";
import { range } from "lodash";
import { useGetTestProps } from "@src/hooks";
import type { Merit } from "@src/api/issuance";
import type { ViewStyle } from "react-native";

export type MeritsListProps = {
  readonly isShareInProgress: boolean;
  readonly merits?: readonly Merit[];
  readonly onPressItem: (meritId: Merit["id"]) => void;
  readonly selectedMeritIds?: ReadonlySet<Merit["id"]>;
};

export const MeritsList = ({
  isShareInProgress,
  merits,
  onPressItem,
  selectedMeritIds,
}: MeritsListProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly headerTextContainer: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
    headerTextContainer: {
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
    },
  });

  const getTestProps = useGetTestProps();

  if (merits === undefined) {
    return (
      <View
        style={styles.container}
        {...getTestProps({
          elementName: "MeritsList",
        })}
      >
        <FlatList
          ItemSeparatorComponent={() => <LineSeparator />}
          ListHeaderComponent={() => (
            <>
              <LineSeparator />
              <View style={styles.headerTextContainer}>
                <Body>
                  <Trans context="sort">A-Z</Trans>
                </Body>
              </View>
              <LineSeparator />
            </>
          )}
          data={range(5)}
          renderItem={({ item }) => <MeritListItemSkeleton key={item} />}
          {...getTestProps({
            elementId: "merits",
            elementName: "MeritsList",
          })}
        />
      </View>
    );
  }

  return (
    <View
      style={styles.container}
      {...getTestProps({
        elementName: "MeritsList",
      })}
    >
      <FlatList
        ItemSeparatorComponent={() => <LineSeparator />}
        ListHeaderComponent={() => (
          <>
            <LineSeparator />
            <View style={styles.headerTextContainer}>
              <Body>
                <Trans context="sort">A-Z</Trans>
              </Body>
            </View>
            <LineSeparator />
          </>
        )}
        data={merits}
        renderItem={({ item }) => (
          <MeritListItem
            isSelected={selectedMeritIds?.has(item.id) === true}
            isShareInProgress={isShareInProgress}
            merit={item}
            onPress={() => {
              onPressItem(item.id);
            }}
          />
        )}
        {...getTestProps({
          elementId: "merits",
          elementName: "MeritsList",
        })}
      />
    </View>
  );
};
