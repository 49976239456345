import { MeritListItemDesktop } from "./MeritListItemDesktop";
import { MeritListItemMobile } from "./MeritListItemMobile";
import { useLayoutType } from "@src/hooks";
import type { Props } from "./types";

const MeritListItem = ({ ...props }: Props) => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <MeritListItemDesktop {...props} />;
  }

  return <MeritListItemMobile {...props} />;
};

export { MeritListItem };
