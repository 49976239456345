import { Banner, ConnectedApps, Greeting, MyMerits, Notifications } from "./components";
import { FocusAwareStatusBar } from "@src/components";
import { HomeHeader } from "./components/HomeHeader.mobile";
import { PersonalQRCodeCard } from "./components/PersonalQRCodeCard";
import { RefreshControl, ScrollView, StyleSheet, View } from "react-native";
import { useFeatureFlags } from "@src/hooks";
import { useMerits } from "@src/api/issuance";
import { useTheme } from "@merit/frontend-components";
import type { FC } from "react";

export const Home: FC = () => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    contentContainer: {
      gap: theme.spacing.l,
      paddingHorizontal: theme.spacing.l,
    },
    sectionContainer: {
      marginBottom: theme.spacing.xxl,
    },
  });

  const { isRefetching, refetch } = useMerits();
  const featureFlags = useFeatureFlags();

  return (
    <>
      <FocusAwareStatusBar backgroundColor={theme.colors.brand.turquiose} />
      <HomeHeader />
      <Banner />
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <Greeting />
          <Notifications />
        </View>
        <ScrollView
          contentContainerStyle={styles.contentContainer}
          refreshControl={
            <RefreshControl
              onRefresh={() => {
                refetch();
              }}
              refreshing={isRefetching}
            />
          }
          showsVerticalScrollIndicator={false}
        >
          <View style={styles.sectionContainer}>
            <ConnectedApps />
          </View>
          {featureFlags.showQrCode === true ? (
            <View style={styles.sectionContainer}>
              <PersonalQRCodeCard />
            </View>
          ) : null}
          <View style={styles.sectionContainer}>
            <MyMerits />
          </View>
        </ScrollView>
      </View>
    </>
  );
};
