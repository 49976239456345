import { Button, Heading, TextInput, useTheme } from "@merit/frontend-components";
import { HEADER_HEIGHT } from "@src/utils/constants/sizes";
import { StyleSheet, View } from "react-native";
import { msg } from "@lingui/macro";
import { useFeatureFlags, useGetTestProps } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useRef } from "react";

export type SideBarContentHeaderProps = {
  readonly isPreviewButtonEnabled: boolean;
  readonly isShareInProgress: boolean;
  readonly onChangeText: (text: string) => void;
  readonly onPressCancel: () => void;
  readonly onPressPreview: () => void;
  readonly onPressShare: () => void;
};

export const SideBarContentHeader = ({
  isPreviewButtonEnabled,
  isShareInProgress,
  onChangeText,
  onPressCancel,
  onPressPreview,
  onPressShare,
}: SideBarContentHeaderProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      flexDirection: "row",
      height: HEADER_HEIGHT,
      justifyContent: "space-between",
    },
    headerItem: {
      flex: 1,
      paddingHorizontal: theme.spacing.xxl,
    },
    shareButtonsInnerContainer: {
      flexDirection: "row",
      gap: theme.spacing.s,
    },
    shareButtonsOuterContainer: {
      alignItems: "flex-end",
    },
  });

  const featureFlags = useFeatureFlags();
  const getTestProps = useGetTestProps();
  const inputRef = useRef(null);
  const { _ } = useLingui();

  return (
    <View style={styles.container}>
      <View style={styles.headerItem}>
        <Heading
          bold
          level="2"
          {...getTestProps({
            elementId: "heading",
            elementName: "SideBarContentHeader",
          })}
        >
          {_(msg({ context: "title", message: "Merits" }))}
        </Heading>
      </View>
      <View style={styles.headerItem}>
        <TextInput
          leftIcon="searchSmallSubdued"
          onChangeText={onChangeText}
          placeholder={_(msg({ context: "placeholder", message: "Search" }))}
          ref={inputRef}
          {...getTestProps({
            elementId: "searchInput",
            elementName: "SideBarContentHeader",
          })}
        />
      </View>
      <View style={[styles.headerItem, styles.shareButtonsOuterContainer]}>
        {/* important to leave the otherwise empty headerItem here to preserve header layout */}
        {featureFlags.showMemberAppShareMeritsFeature === true && (
          <View style={styles.shareButtonsInnerContainer}>
            {isShareInProgress ? (
              <>
                <Button
                  onPress={onPressCancel}
                  size="small"
                  text={_(msg`Cancel`)}
                  type="secondary"
                />
                <Button
                  disabled={!isPreviewButtonEnabled}
                  onPress={onPressPreview}
                  size="small"
                  text={_(msg`Preview`)}
                />
              </>
            ) : (
              <Button
                key="share" // RN incorrectly carries over the active state to the Cancel button without this
                onPress={onPressShare}
                size="small"
                text={_(msg`Share`)}
                type="secondary"
              />
            )}
          </View>
        )}
      </View>
    </View>
  );
};
