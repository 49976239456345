import { Header, Icon as PEIcon } from "@src/components";
import { useNavigation } from "@src/hooks";
import { useTheme } from "@merit/frontend-components";

export const HomeHeader = () => {
  const navigation = useNavigation();
  const { theme } = useTheme();

  const handleLeftOnPress = () => {
    navigation.toggleDrawer();
  };

  const handleRightOnPress = () => {
    navigation.navigate("Merits");
  };

  return (
    <Header
      leftElement={<PEIcon name="menu_left" size={theme.fontSizes.xl.fontSize} />}
      leftOnPress={handleLeftOnPress}
      rightElement={<PEIcon name="search" size={theme.fontSizes.xl.fontSize} />}
      rightOnPress={handleRightOnPress}
    />
  );
};
