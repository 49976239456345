import {
  Body,
  Button,
  Heading,
  Icon,
  LineSeparator,
  RadioButtonGroup,
  useTheme,
} from "@merit/frontend-components";
import { FlatList, Platform, StyleSheet, View } from "react-native";
import { Header, MeritListItem } from "@src/components";
import { Plural, Trans, msg } from "@lingui/macro";
import { setStringAsync, setUrlAsync } from "expo-clipboard";
import { useAlerts, useLayoutType, useNavigation } from "@src/hooks";
import { useEffect, useState } from "react";
import { useLingui } from "@lingui/react";
import { useMerits } from "@src/api/issuance";
import { useShareMeritsStore } from "@src/stores/shareMerits";
import type { ViewStyle } from "react-native";

export const CreateShareMeritsConfirmation = () => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly contentContainer: ViewStyle;
    readonly headerContainer: ViewStyle;
    readonly radioButtonsContainer: ViewStyle;
    readonly selectedMeritsOuter: ViewStyle;
    readonly successContainer: ViewStyle;
    readonly successButtonsContainer: ViewStyle;
    readonly successLinkContainer: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
    contentContainer: {
      alignItems: "center",
      alignSelf: "center",
      flex: 1,
      maxWidth: 1280,
      paddingBottom: theme.spacing.xl,
      paddingHorizontal: theme.spacing.xl,
      width: "100%",
    },
    headerContainer: {
      alignItems: "center",
    },
    radioButtonsContainer: {
      marginBottom: theme.spacing.l,
    },
    selectedMeritsOuter: {
      alignSelf: "stretch",
      flex: 1,
    },
    successButtonsContainer: {
      alignItems: "flex-end",
    },
    successContainer: {
      rowGap: theme.spacing.l,
    },
    successLinkContainer: {
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      padding: theme.spacing.s,
    },
  });

  const { goBackOrGoHome, goHome } = useNavigation();
  const { _ } = useLingui();
  const layoutType = useLayoutType();
  const { data: merits } = useMerits();
  const { sendAlert } = useAlerts();

  const selectedMeritIds = useShareMeritsStore(state => state.selectedMeritIds);

  const [daysToExpire, setDaysToExpire] = useState<number | undefined>();
  const [isRequestInFlight, setIsRequestInFlight] = useState(false);
  const [generatedShareLink, setGeneratedShareLink] = useState<string | undefined>();

  // take the user home if they navigate directly to this screen with an invalid state
  useEffect(() => {
    if (selectedMeritIds.length === 0) {
      goHome();
    }
  }, [goHome, selectedMeritIds.length]);

  const onPressGenerateShareLink = () => {
    setIsRequestInFlight(true);
    setTimeout(() => {
      // TODO: actually make a request to Share Merits API
      const terms = ["weather", "wikipedia", "gomerits", "typescript", "golang"];
      const term = terms[Math.floor(Math.random() * terms.length)];
      setGeneratedShareLink(`https://www.google.com/search?q=${term ?? ""}`);
      setIsRequestInFlight(false);
    }, 1000);
  };

  return (
    <View style={styles.container}>
      <Header
        containerStyle={styles.headerContainer}
        leftElement={<Icon name="closeLargeAction" />}
        leftOnPress={() => {
          goBackOrGoHome();
        }}
        rightElement={
          generatedShareLink !== undefined || layoutType === "desktop" ? (
            <View />
          ) : (
            <Button
              disabled={isRequestInFlight || daysToExpire === undefined}
              iconLeft={isRequestInFlight ? "timeMediumSubdued" : "sendMediumDefault"}
              onPress={onPressGenerateShareLink}
              shape="square"
              type="primary"
            />
          )
        }
        title={
          generatedShareLink === undefined
            ? _(msg({ context: "title", message: "Ready to Share?" }))
            : _(msg({ context: "title", message: "Success" }))
        }
      />
      <View style={styles.contentContainer}>
        {generatedShareLink === undefined ? (
          <>
            <Heading level="3">
              <Plural
                one={<Trans>You have selected the following merit to share:</Trans>}
                other={<Trans>You have selected the following # merits to share:</Trans>}
                value={selectedMeritIds.length}
              />
            </Heading>
            <FlatList
              ItemSeparatorComponent={LineSeparator}
              data={selectedMeritIds}
              keyExtractor={item => item}
              renderItem={({ item }) => {
                const matchingMerit = merits?.find(merit => merit.id === item);

                return matchingMerit === undefined ? null : <MeritListItem merit={matchingMerit} />;
              }}
              style={styles.selectedMeritsOuter}
            />
            <View style={styles.radioButtonsContainer}>
              <RadioButtonGroup
                onChange={val => {
                  setDaysToExpire(val);
                }}
                options={[
                  {
                    label: _(msg`I do not want this link to expire`),
                    value: -1,
                  },
                  {
                    label: _(msg`I want this link to expire after 7 days`),
                    value: 7,
                  },
                ]}
              />
            </View>
            {layoutType === "desktop" && (
              <Button
                disabled={isRequestInFlight || daysToExpire === undefined}
                onPress={onPressGenerateShareLink}
                text={isRequestInFlight ? _(msg`Generating…`) : _(msg`Generate Share Link`)}
              />
            )}
          </>
        ) : (
          <View style={styles.successContainer}>
            <Heading level="3">
              <Plural
                one={<Trans>Your merit is ready to be shared</Trans>}
                other={<Trans>Your merits are ready to be shared</Trans>}
                value={selectedMeritIds.length}
              />
            </Heading>
            <View style={styles.successLinkContainer}>
              <Body>{generatedShareLink}</Body>
            </View>
            <View style={styles.successButtonsContainer}>
              <Button
                onPress={async () => {
                  const method = Platform.OS === "web" ? setStringAsync : setUrlAsync;
                  await method(generatedShareLink);
                  sendAlert({
                    id: "copy-success",
                    text1: _(msg`Success`),
                    text2: _(msg`Link copied to clipboard`),
                    type: "success",
                  });
                }}
                text={_(msg`Copy`)}
              />
            </View>
          </View>
        )}
      </View>
    </View>
  );
};
