import { create } from "zustand";
import type { Merit } from "@src/api/issuance";

type SelectedMeritIds = readonly Merit["id"][];

type ShareMeritsState = {
  readonly selectedMeritIds: SelectedMeritIds;
  readonly setSelectedMeritIds: (ids: SelectedMeritIds) => void;
};

export const useShareMeritsStore = create<ShareMeritsState>()(set => ({
  selectedMeritIds: [],
  setSelectedMeritIds: (ids: SelectedMeritIds) => {
    set(_ => ({
      selectedMeritIds: ids,
    }));
  },
}));
